
svg.pattern-lock g.lock-lines line {
    stroke-width: 1.5;
    stroke: black;
    opacity: 0.5;
}

svg.pattern-lock g.lock-dots circle {
    stroke: transparent;
    fill: black;
    stroke-width: 13.5;
}

svg.pattern-lock g.lock-actives circle {
    fill: black;
    opacity: .2;
    animation: lock-activate-dot .15s 0s ease 1;
    transform-origin: center;
}

svg.pattern-lock g.lock-lines line {
    stroke-width: 1.5;
    stroke-linecap: round;
}

svg.pattern-lock.success g.lock-actives circle {
    fill: green;
}

svg.pattern-lock.error g.lock-actives circle {
    fill: red;
}

@keyframes lock-activate-dot {
    0% {
        transform: scale(0);
    }
    75% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1.0);
    }
}