@font-face {
  font-family: 'Open Sans';
  src: src('./font/OpenSans-Regular.ttf') format('truetype'); /* Pfad zur Font-Datei anpassen */
}
body {
  margin: 0;

  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root{
  min-height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media screen and (max-width: 768px) {
  .MenueBar {
    position: absolute;
    top: 3.5rem;
    right: 0;
  }
}
  


