#root{
  background: white;
}
.reparaturen-Boxen{
    clip-path: polygon(0 0,110% 0,100% 100%,0 86%);
}
.aktionspreisLine{
  position: relative;
}
.aktionspreisLine::after{
  content: "";
  position: absolute;
  width: 100%;
  transform: translate(0px, 0.7rem) rotate(135deg);
  height: 1.5px;
  background-color: red;
  top: 0;
  left: 0;
}
.popup-preview p {
  font-size: 16px;
  color: #666;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  text-align: justify;
}
.MuiInputBase-root > fieldset{
  border: none;
}
.display-none{
  display: none;
}
.transition_up{
    transition: all 2s ease-out;
    height: 0;
    display: none;
}
.PatternLock{
    position:  absolute;
    right:  -50%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    padding: 1rem;
    text-Align: center;
}
.selected-item{
    border: 5px solid limegreen;
}
.col-template{
    grid-template-columns: 0.75fr 1fr 0.5fr;
}
.col-template-4{
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 768px) {
    .col-template{
        grid-template-columns: 1fr;
    }
    .PatternLock{
        position:  unset;
        right:  0;
        transform: 0;
        width:  100%;
        max-Width: 100%;
    }
    
}

@media screen and (max-width: 400px)
{
  .react-datepicker__time-container {
    width: 100% !important;
  }
  .react-datepicker__month-container{
    width: 100% !important;
  }
  .react-datepicker__time-box{
    width: 100% !important;
  }
  .react-datepicker__navigation--next{
    right: 0 !important;
  }
  .react-datepicker__navigation--previous{
    left: 0 !important;
  }
}

#send-animation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    font-family: Arial, sans-serif;
  }
  
  .paper-plane {
    position: relative;
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    animation: fly 2s linear infinite ;
  }
  .paper-plane2 {
    position: relative;
    width: 100px;
    height: 100px;
    transform: translateX(-500%);
    background-size: contain;
    background-repeat: no-repeat;
    animation-name: fly2;
    animation-delay: 1s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    
  }

  button[disabled]
  {
    opacity: 0.5;
    cursor: unset;
  }
  @keyframes fly {
    0% {
      transform: translateX(-100%) translateY(0);
    }
    100% {
      transform: translateX(100%) translateY(0);
    }
  }
  @keyframes fly2 {
    0% {
      transform: translateX(-100%) translateY(0) ;
    }
    100% {
      transform: translateX(100%) translateY(0) ;
    }
  }
  #send-animation-text {
    margin-top: 10px;
  }

  .react-datepicker__time-list-item--disabled{
    display: none;
  }


  .newBG{
    background-color: #2663A1;
    /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23055491' fill-opacity='0.6'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E"); */
  }